import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store'
const LoginView = ()=>import(/* webpackChunkName: "page-login" */ '@/views/login/LoginView.vue')
const AdminView = ()=>import(/* webpackChunkName: "page-login" */ '@/views/login/LoginTipoUsuarioView.vue')

// verifica se o tipo_usuario existe, senão se torna vázio
Store.state.usuario_logado.tipo_usuario = localStorage.getItem('usuario')
Store.state.usuario_logado.token = localStorage.getItem('token') || undefined
Store.state.usuario_logado.usuario = localStorage.getItem('usuarioLogado')
let usuario = Store.state.usuario_logado.tipo_usuario
let token = localStorage.getItem('token') || undefined
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta:{title:"Login", isAuth: false},
  },
  {
    path: '/login/admin',
    name: 'Admin',
    component: AdminView,
    meta:{title:"Login", isAuth: false},
  },
  {
    path: '/operacao',
    component: ()=> import(/* webpackChunkName: "page-profile" */ '@/pages/PageOperacao.vue'),
    meta:{isAuth: true},
    beforeEnter:(to, from, next)=>{
      if(token !== undefined &&  usuario !== 'operacao') next(`/${usuario}/inicio`)
      else next()
    },
    children: [
      {
        path: '/operacao/inicio',
        name: 'OperacaoHome',
        component: ()=>import(/* webpackChunkName: "page-dashboard" */ '@/views/OperacaoHomeView.vue'),
        meta:{title:"Dashboard"},
      }
    ]
  },
  {
    path: '/produtor',
    component: ()=> import(/* webpackChunkName: "page-profile" */ '@/pages/PageProdutor.vue'),
    meta:{isAuth: true},
    beforeEnter:(to, from, next)=>{
      if(token !== undefined &&  usuario !== 'produtor') next(`/${usuario}/inicio`)
      else next()
    },
    children: [
      {
        path: '/produtor/inicio',
        name: 'ProdutorHome',
        component: ()=>import(/* webpackChunkName: "page-dashboard" */ '@/views/ProdutorHomeView.vue'),
        meta:{title:"Dashboard"},
      },
      {
        path: '/produtor/relatorios/relatorio-venda-por-produto',
        name: 'PageProdutorRelatorioVendaPorProduto',
        component: () => import(/* webpackChunkName: "pages-relatorios" */ '@/views/profiles/produtor/relatorios/PageProdutorRelatorioVendaPorProduto.vue'),
        meta:{title:"Relatório venda por produto"},
      },
      {
        path: '/produtor/relatorios/relatorio-venda-por-operacao',
        name: 'PageProdutorRelatorioVendaPorOperacao',
        component: () => import(/* webpackChunkName: "pages-relatorios" */ '@/views/profiles/produtor/relatorios/PageProdutorRelatorioVendaPorOperacao.vue'),
        meta:{title:"Relatório venda por operação"},
      },
      {
        path: '/produtor/relatorios/relatorio-venda-por-forma-pag',
        name: 'PageProdutorRelatorioVendaPorFormasPag',
        component: () => import(/* webpackChunkName: "pages-relatorios" */ '@/views/profiles/produtor/relatorios/PageProdutorRelatorioVendaPorFormasPag.vue'),
        meta:{title:"Relatório venda por formas de pagamento"},
      },
      {
        path: '/produtor/relatorios/relatorio-venda-por-usuario',
        name: 'PageProdutorRelatorioVendaPorUsuario',
        component: () => import(/* webpackChunkName: "pages-relatorios" */ '@/views/profiles/produtor/relatorios/PageProdutorRelatorioVendaPorUsuario.vue'),
        meta:{title:"Relatório venda por usuário"},
      },
      {
        path: '/produtor/relatorios/relatorio-cancelamento-transacao',
        name: 'PageProdutorRelatorioCancelamentoTransacoes',
        component: () => import(/* webpackChunkName: "pages-relatorios" */ '@/views/profiles/produtor/relatorios/PageProdutorCancelamentoTransacao.vue'),
        meta:{title:"Relatório cancelamento de transações"},
      },
      {
        path: '/produtor/relatorios/relatorio-devolucao-saldo',
        name: 'PageProdutorRelatorioDevolucaoSaldo',
        component: () => import(/* webpackChunkName: "pages-relatorios" */ '@/views/profiles/produtor/relatorios/PageProdutorDevolucaoSaldo.vue'),
        meta:{title:"Relatório devolução saldo"},
      },
      {
        path: '/produtor/relatorios/relatorio-venda-detalhada',
        name: 'PageProdutorRelatorioVendaDetalhada',
        component: () => import(/* webpackChunkName: "pages-relatorios" */ '@/views/profiles/produtor/relatorios/PageProdutorRelatorioVendaDetalhada.vue'),
        meta:{title:"Relatório venda detalhada"},
      },
      {
        path: '/produtor/relatorios/relatorio-cortesias',
        name: 'PageProdutorRelatorioCortesias',
        component: () => import(/* webpackChunkName: "pages-relatorios" */ '@/views/profiles/produtor/relatorios/PageProdutorRelatorioCortesia.vue'),
        meta:{title:"Relatório de cortesias"},
      },
      {
        path: '/produtor/relatorios/relatorio-reimpressoes',
        name: 'PageProdutorRelatorioReimpresoes',
        component: () => import(/* webpackChunkName: "pages-relatorios" */ '@/views/profiles/produtor/relatorios/PageProdutorRelatorioReimpressoes.vue'),
        meta:{title:"Relatório de reimpressões"},
      },
      {
        path: '/produtor/relatorios/relatorio-estorno-produtos',
        name: 'PageProdutorRelatorioEstornoProdutos',
        component: () => import(/* webpackChunkName: "pages-relatorios" */ '@/views/profiles/produtor/relatorios/PageProdutorRelatorioEstornoProdutos.vue'),
        meta:{title:"Relatório de estorno de produtos"},
      },
      {
        path: '/produtor/cadastros/cadastrar-categoria',
        name: 'PageProdutorCadastroCategoria',
        component: () => import(/* webpackChunkName: "pages-cadastros" */ '@/views/profiles/produtor/categorias/CadastroCategoria.vue'),
        meta:{title:"Cadastrar categoria"},
      },
      {
        path: '/produtor/cadastros/cadastrar-produtos',
        name: 'PageProdutorCadastroProduto',
        component: () => import(/* webpackChunkName: "pages-cadastros" */ '@/views/profiles/produtor/produtos/CadastroProduto.vue'),
        meta:{title:"Cadastrar produto"},
      },
      {
        path: '/produtor/cadastros/cadastrar-operacao',
        name: 'PageProdutorCadastroOperacao',
        component: () => import(/* webpackChunkName: "pages-cadastros" */ '@/views/profiles/produtor/operacao/CadastroOperacao.vue'),
        meta:{title:"Cadastrar operação"},
      },
      {
        path: '/produtor/operacao/lista-operacao',
        name: 'PageProdutorOperacoes',
        component: () => import(/* webpackChunkName: "pages-cadastros" */ '@/views/profiles/produtor/operacao/ListaOperacao.vue'),
        meta:{title:"Cadastrar operação"},
      },
      {
        path: '/produtor/cadastros/editar-operacao/:idoperacao',
        name: 'EdicaoOperacao',
        component: () => import(/* webpackChunkName: "pages-cadastros" */ '@/views/profiles/produtor/operacao/EdicaoOperacao.vue'),
        meta:{title:"Editar operação"},
      },
    ]
  },
  {
    path: '/consumidor',
    component: () => import(/* webpackChunkName: "page-profile" */'@/pages/PageConsumidor.vue'),
    meta:{isAuth: true},
    beforeEnter:(to, from, next)=>{
      if(token !== undefined &&  usuario !== 'consumidor') next(`/${usuario}/inicio`)
      else next()
    },
    children: [
      {
        path: '/consumidor/inicio',
        name: 'ConsumidorHome',
        component: ()=>import(/* webpackChunkName: "page-dashboard" */ '@/views/ConsumidorHomeView.vue'),
        meta: {title: 'Dashboard'}
      },      
      {
        path: '/consumidor/recarga',
        name: 'ConsumidorRecarga',
        component: ()=>import(/* webpackChunkName: "page-dashboard" */ '@/views/profiles/consumidor/RecargaCreditos.vue'),
        meta: {title: 'Recarga'}
      },
      {
        path: '/consumidor/formapagamento',
        name: 'FormaPagamento',
        component: ()=>import(/* webpackChunkName: "page-dashboard" */ '@/views/profiles/consumidor/EscolhaFormaPagamento.vue'),
        meta: {title: 'Forma de pagamento'}
      },      
      {
        path: '/consumidor/pagamentocartao',
        name: 'PagamentoCartaoCredito',
        component: ()=>import(/* webpackChunkName: "page-dashboard" */ '@/views/profiles/consumidor/PagamentoCartaoCredito.vue'),
        meta: {title: 'Pagamento cartão de crédito'}
      },      
      {
        path: '/consumidor/minhasrecargas',
        name: 'MinhasRecargas',
        component: ()=>import(/* webpackChunkName: "page-dashboard" */ '@/views/profiles/consumidor/MinhasRecargas.vue'),
        meta: {title: 'Minhas recargas'}
      },      
      {
        path: '/consumidor/cadastros/cadastrar-conta',
        name: 'PageConsumidorCadastroConta',
        component: ()=>import(/* webpackChunkName: "pages-cadastros" */ '@/views/profiles/consumidor/cadastros/CadastroConta.vue'),
        meta: {title: 'Cadastro conta'}
      },
      {
        path: '/consumidor/cadastros/Cadastrar-dados-usuario',
        name: 'PageConsumidorCadastroUsuario',
        component: ()=>import(/* webpackChunkName: "page-cadastros" */ '@/views/profiles/consumidor/cadastros/DadosCadastroUsuario.vue'),
        meta: {title: 'Dados pessoais'}
      }      
    ]
  },
  {
    path:'*',
    redirect: '/error'
  },
  {
    path:'/error',
    component: ()=> import(/* webpackChunkName: "page-error" */ '../views/PageError.vue'),
    meta:{title:"Erro"}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  document.title = to.meta.title

  if(to.matched.some(report => report.meta.isAuth)){
    next()
  }else{
    next()
  }
})

export default router
