import Vue from 'vue';
import store from '@/store'
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(DatetimePicker)

// Filtro para formatar valores
Vue.filter('real', function(v) {
  var formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
  });

  let preco = formatter.format(v).replace('R$', '').trim()

  return preco
});

Vue.config.productionTip = false;

new Vue({
  store: store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
