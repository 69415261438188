<template>
  <v-app>
    <v-main style="margin: 0; padding: 0;">
      <v-container fluid style="margin: 0; padding: 0; height: 100%;">
        <router-view style="overflow: hidden;"/>
        <footer-page></footer-page>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import FooterPage from '@/components/FooterPage.vue'
export default {
  name: 'App',
  components: {
    FooterPage
  },
};
</script>

<style setup>
*{
  print-color-adjust: exact;
}
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #8661ff92;
  }

  ::-webkit-scrollbar-thumb {
    background: hsla(254, 56%, 30%, 0.5);
  }
</style>