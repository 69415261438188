<template>
    <v-footer app class="rodape py-0 px-1">
      <v-col cols="4" class="text-left pa-0">
        <span class="ml-2">v7.0</span>
      </v-col>
      <v-col cols="4" class="text-center pa-0">
        <img src="@/assets/img/imply-logo.png" style="margin-top: 3px;" alt="logo_elevenCash" height="25px">
      </v-col>
      <v-col cols="4" class="text-right pa-0">
        <h5><span class="texto-rodape">{{ user.tipoUsuario }} {{ user.userLogado }}</span></h5>
      </v-col>
    </v-footer>
</template>

<script>
  export default {
    computed:{
      user(){
        this.$store.dispatch('tipoUsuario')
        let userLogado = this.$store.getters.retornaInfoUsuario.usuario
        let tipoUsuario = this.$store.getters.retornaInfoUsuario.tipoUsuario
        return{ tipoUsuario, userLogado }
      }
    },
  }
</script>

<style scoped>
  .rodape {
    background-color: #e6e6e6;
  }
</style>