import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        usuario_logado: {
            usuario: "",
            tipo_usuario: "",
            token: "",
            idpessoa: "",
            nome: "",
            email: "",
            cpf: "",
            inicio_reembolso: "",
            fim_reembolso: "",
            valor_reembolso: "",
            pode_pedir_reembolso: ""
        },
        error:{
            msgError:'Página não encontrada!',
            pathError: '/'
        },
        alertMessage:{
            status: null
        },
        recaptchaObject: { 
            site_key_recaptcha: '', 
            recaptcha_ativo: '0'
        },
        checkTermo: false,
    },
    getters:{
        retornaInfoUsuario(state){
            let usuario = state.usuario_logado.usuario;
            let tipoUsuario = state.usuario_logado.tipo_usuario;

            let idpessoa = state.usuario_logado.idpessoa;
            let nome = state.usuario_logado.nome;
            let email = state.usuario_logado.email;
            let cpf = state.usuario_logado.cpf;
            let inicio_reembolso = state.usuario_logado.inicio_reembolso;
            let fim_reembolso = state.usuario_logado.fim_reembolso;
            let valor_reembolso = state.usuario_logado.valor_reembolso;
            let pode_pedir_reembolso = state.usuario_logado.pode_pedir_reembolso;

            return {
                usuario, 
                tipoUsuario,
                idpessoa,
                nome,
                email,
                cpf,
                inicio_reembolso,
                fim_reembolso,
                valor_reembolso,
                pode_pedir_reembolso
            }
        },
        getCheckTermo: state => {
            return state.checkTermo;
        },
        getRecaptchaObject: state => {
            return state.recaptchaObject;
        },
    },
    mutations:{
        formataTextoUsuario(state){
            let tipoUsuario = state.usuario_logado.tipo_usuario
            if(tipoUsuario !== null ){
                state.usuario_logado.tipo_usuario = tipoUsuario.charAt(0).toUpperCase() + tipoUsuario.slice(1) + ":"
            }
            if(tipoUsuario === null || tipoUsuario === ''){
                state.usuario_logado.tipo_usuario = ''
            }
        },
        resetaLogin(state){
            state.usuario_logado = {
                usuario: null,
                tipo_usuario: null,
                token: null
            }
            localStorage.clear('usuarioLogado')
            localStorage.clear('token')
            localStorage.clear('usuario')
        },
        changeRecaptchaObject: (state, data) => {
            state.recaptchaObject = data;
        },
        changeCheckTermo: (state, data) => {
            state.checkTermo = data;
        },
        changeDadosConsumidor: (state, data) => {
            state.usuario_logado = data;
        }
    },
    actions:{
        tipoUsuario({commit}){
            commit('formataTextoUsuario')
        },
        resetaLogin({commit}){
            commit('resetaLogin')
        }
    }
})